import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Modal, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import LtFormDropdown from '../../../core/components/lt-form-dropdown';
import LtFormInput from '../../../core/components/lt-form-input';
import LtFormTextArea from '../../../core/components/lt-form-textarea';
import ProductTypesService from '../../../shared/services/product-types.service';
import { productService } from '../../../shared/services/products.service';
import { actions } from '../../../stores';
import ProductAttributeService from '../../../shared/services/product-attribute.service';
import {
  getCommunitiesAction,
  getModeratorsAction,
  addModeratorAction,
  removeModeratorAction,
  getCommunityAction,
  UpdateCommunityAction,
} from "../../../../community/redux/actions/adminActions";
import{getCommunity} from "../../../../community/redux/api/adminAPI";
import { useSelector, useDispatch } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UpdateCommunity = () => {

  const dispatch = useDispatch();
  
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      supervisor: [],
      description: '',
      attributes: [],
    },
  });
  const { commnuityId } = useParams();
  const [morderator, setModerator] = useState([]);
  const [images, setImages] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState();
  const [community, setCommunity] = useState();
  // const communities = useSelector((state) => state.admin?.communities);
  const supervisor = useSelector((state) => state.admin?.supervisor);
  // const community = useSelector((state) => state.admin?.community);
  useEffect(() => {
    
    // setCommunity(getCommunityAction(commnuityId));
    // dispatch(getCommunityAction(commnuityId));
    getCommunityDetail();
    dispatch(getModeratorsAction());
    // setFormValue(community);
}, [dispatch]);

const getCommunityDetail = async () => {
  try {
    dispatch(actions.showLoading());
    const response = await getCommunity(commnuityId);
    
    if (response.error){
      messageApi.error(response.error);
    }else{
    const community = response.data;
    setFormValue(community);
    setCommunity(community);
    }
  } catch (error) {
    messageApi.error(error?.response?.data?.message || error.message);
  } finally {
    dispatch(actions.hideLoading());
  }
}


  const handleUpdateCommunity = async (formValues) => {
    try {
      dispatch(actions.showLoading());
      await dispatch(UpdateCommunityAction(commnuityId, formValues));
      messageApi.success('Cập nhật thành công');
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

    const handleRemoveExistedImage = (removedUrl) => {
    setImages(images.filter((itemUrl) => itemUrl !== removedUrl));
  };

    const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file?.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
    const handleChange = (info) => {};
    const handleCancel = () => setPreviewOpen(false);
    
    const setFormValue = (product) => {
      const formValues = {};
      for (let field in product) {
        switch (field) {
          case 'name': {
            formValues.name = product?.name;
            break;
          }
          case 'supervisor': {
            
            formValues.supervisor = product.supervisor.map((moderator) => moderator?._id);
            
            break;
          }
          case 'description': {
            formValues.description =  product?.description;
            break;
          }
          default: {
            break;
          }
        }
      }
      reset(formValues);
    }
  return (
    <>
      {community ? (
        <>
          {' '}
          {contextHolder}
          <div className='container'>
            <Form layout='vertical' onFinish={handleSubmit(handleUpdateCommunity)}>
              <LtFormInput
                name='name'
                control={control}
                error={errors?.name}
                label='Tên Cộng Đồng'
                placeholder='Tên cộng đồng'
                rules={{
                  required: 'Vui lòng nhập thông tin',
                }}
              />
              {/* <LtFormDropdown
                name='type'
                control={control}
                error={errors.type}
                label='Loại sản phẩm'
                placeholder='Loại sản phẩm'
                dropdownOptions={productTypes.map((type) => ({
                  label: type?.name,
                  value: type?._id,
                }))}
                rules={{
                  required: 'Vui lòng chọn loại sản phẩm',
                }}
              /> */}
              <LtFormDropdown
                isMultiple = {true}
                name='supervisor'
                control={control}
                error={errors.supervisor}
                label='Người quản trị'
                placeholder='Người quản trị'
                dropdownOptions={supervisor.map((attribute) => ({
                  label:  `${attribute?._id} - ${attribute?.name}`,
                  value: attribute?._id,
                }))}
              />

              {/* <div>
                <p className='m-0 mb-1'>Hình Đại Diện Cộng Đồng</p>
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                  }}
                  className='my-2'>
                  {images && images.length ? (
                    <>
                      {images.map((url) => (
                        <div className='text-center'>
                          <img
                            alt='community'
                            src={url}
                            style={{ width: 100, height: 100, objectFit: 'contain' }}
                          />
                          <Button
                            type='text'
                            danger
                            className='mt-1'
                            onClick={() => handleRemoveExistedImage(url)}>
                            Xoá
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : null}
                  <Upload
                    multiple
                    maxCount={5 - (images && images.length ? images.length : 0)}
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={() => {
                      return false;
                    }}>
                    {(images && images.length ? images.length : 0) + fileList?.length >=
                    5 ? null : (
                      <span>Tải ảnh lên</span>
                    )}
                  </Upload>
                </div>
              </div>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                  alt='example'
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
              <LtFormInput
                label='Giá'
                control={control}
                name='price'
                placeholder='Giá'
                error={errors.price}
                rules={{
                  required: 'Vui lòng nhập thông tin',
                  validate: (value) => {
                    if (isNaN(+value) || +value < 0) {
                      return 'Giá tiền không hợp lệ';
                    }
                    return null;
                  },
                }}
              />
              <LtFormInput
                label='Số lượng'
                control={control}
                name='countInStock'
                placeholder='Số lượng'
                error={errors.countInStock}
                rules={{
                  required: 'Vui lòng nhập thông tin',
                  validate: (value) => {
                    if (isNaN(+value) || +value < 0) {
                      return 'Số lượng không hợp lệ';
                    }
                    return null;
                  },
                }}
              /> */}

              <LtFormTextArea
                label='Mô tả cộng đồng'
                name='description'
                control={control}
                error={errors.description}
                placeholder='Nhập mô tả cộng đồng'
                rules={{
                  required: 'Vui lòng nhập thông tin',
                }}
              />

              <div className='flex ai-center jc-between'>
                <NavLink to='/admin/cong-dong'>
                  <Button size='large' htmlType='button' icon={<ArrowLeftOutlined />}>
                    Quay lại danh sách
                  </Button>
                </NavLink>
                <Button type='primary' size='large' htmlType='submit'>
                  Lưu thay đổi
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <div className='flex ai-center jc-center flex-col'>
          <Empty description='Không tìm thấy sản phẩm' />
          <NavLink to='/admin/cong-dong' className='mt-4'>
            <Button size='large' htmlType='button' icon={<ArrowLeftOutlined />}>
              Quay lại danh sách
            </Button>
          </NavLink>
        </div>
      )}
    </>
  );
};

export default UpdateCommunity;
