import { DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Form, Image, Space, Tooltip, Upload, message, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import LtDynamicTable from '../../../core/components/lt-dynamic-table';
import LtFormInput from '../../../core/components/lt-form-input';
import LtFormModal from '../../../core/components/lt-form-modal';
import ProductAttributeService from '../../../shared/services/product-attribute.service';
import { actions } from '../../../stores';

const ProductAttribute = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ProductAttribute, setProductAttribute] = useState([]);
  const [ProductAttributeDeleted, setProductAttributeDeleted] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [imageUrl, setImageUrl] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [productAtrributeId, setProductAtrributeId] = useState(null);

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { name: '' } });

  const getProductAttribute = async () => {
    try {
      
      dispatch(actions.showLoading());
      const ProductAttribute = await ProductAttributeService.getAll();
      setProductAttribute(ProductAttribute);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };
  const getProductAttributeDeleted = async () => {
    try {
      dispatch(actions.showLoading());
      const ProductAttributeDeleted = await ProductAttributeService.getAllDeleted();
      setProductAttributeDeleted(ProductAttributeDeleted);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  }

  const handleCloseModal = () => {
    revokeImageUrl();
    reset({ name: '' });
    setIsOpen(false);
    setIsUpdating(false);
    setImageFile(null);
    setProductAtrributeId(null);
  };

  const revokeImageUrl = () => {
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }
    setImageFile(null);
    setImageUrl(null);
  };

  const handleUploadImageFile = ({ file }) => {
    const isImage = file.type.startsWith('image/');
    const isSizeValid = file.size / 1024 / 1024 < 10;
    if (!isImage) {
      return messageApi.warning('Chi cho phép upload hình ảnh');
    }
    if (!isSizeValid) {
      return messageApi.warning('Kích thước file quá lớn. Vui lòng chọn file nhỏ hơn 10MB.');
    }

    revokeImageUrl();
    const imageUrl = URL.createObjectURL(file);
    setImageFile(file);
    setImageUrl(imageUrl);
  };

  const openupdateProductAttributeDialog = async (productAttribute) => {
    reset({
      name: productAttribute?.name,
    });
    setImageUrl(productAttribute.imageUrl);
    setProductAtrributeId(productAttribute?._id);
    setIsUpdating(true);
    setIsOpen(true);
  };

  const createProductAttribute = async (formValue) => {
    try {
      dispatch(actions.showLoading());
      const formData = new FormData();
      formData.append('name', formValue?.name);
      // formData.append('image', imageFile);
      // formData.append('imageUrl', imageUrl);
      const newProductType = await ProductAttributeService.create(formData);
      setProductAttribute([newProductType, ...ProductAttribute]);
      handleCloseModal();
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const updateProductAttribute = async (formValue) => {
    try {
      
      dispatch(actions.showLoading());
      const formData = new FormData();
      formData.append('name', formValue?.name);
      // if (imageFile) {
      //   formData.append('image', imageFile);
      //   formData.append('imageUrl', imageUrl);
      // }
      await ProductAttributeService.update(productAtrributeId, formData);
      handleCloseModal();
      getProductAttribute();
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };
  const handleActiveProductAtribute = async (productAtrributeId) => {
      Swal.fire({
        icon: 'question',
        title: 'Vô Hiệu thành phần Sản Phẩm',
        text: 'Bạn có chắc là muốn vô hiệu thành phần sản phẩm này không?',
        showCancelButton: true,
        cancelButtonText: 'Huỷ',
        confirmButtonText: 'Xác nhận',
        confirmButtonColor: 'red',
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          try {
            dispatch(actions.showLoading());
            const formData = new FormData();
            formData.append('isDeleted', false);
            // if (imageFile) {
            //   formData.append('image', imageFile);
            //   formData.append('imageUrl', imageUrl);
            // }
            await ProductAttributeService.update(productAtrributeId, formData);
            messageApi.success('Vô hiệu thành phần sản phẩm thành công');
            getProductAttribute();
            getProductAttributeDeleted();
          } catch (error) {
            messageApi.error(error?.response?.data?.message || error.message);
          } finally {
            dispatch(actions.hideLoading());
          }
        }
      });
  }

  const handleSubmitProductTypeForm = (formValue) => {
    if (isUpdating) {
      updateProductAttribute(formValue);
    } else {
      createProductAttribute(formValue);
    }
  };

  const handleDeleteProductType = async (productAtrributeId) => {
    Swal.fire({
      icon: 'question',
      title: 'Vô Hiệu thành phần Sản Phẩm',
      text: 'Bạn có chắc là muốn vô hiệu thành phần sản phẩm này không?',
      showCancelButton: true,
      cancelButtonText: 'Huỷ',
      confirmButtonText: 'Xác nhận',
      confirmButtonColor: 'red',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          dispatch(actions.showLoading());
          await ProductAttributeService.delete(productAtrributeId);
          messageApi.success('Vô hiệu thành phần sản phẩm thành công');
          getProductAttribute();
          getProductAttributeDeleted();
        } catch (error) {
          messageApi.error(error?.response?.data?.message || error.message);
        } finally {
          dispatch(actions.hideLoading());
        }
      }
    });
  };

  const columns = useMemo(() => {
    return [
      {
        key: '1',
        title: '#',
        dataIndex: '_id',
        render: (value) => value.slice(-7, -1),
        align: 'center',
      },
      // {
      //   key: '2',
      //   title: 'thành phần sản phẩm',
      //   dataIndex: 'imageUrl',
      //   render: (value) => (
      //     <Image src={value} style={{ width: 120, height: 120, objectFit: 'contain' }} />
      //   ),
      //   align: 'center',
      // },
      {
        key: '3',
        title: 'Tên',
        dataIndex: 'name',
        render: (value) => <span className='text-capitalize'>{value}</span>,
      },
      {
        key: '4',
        title: null,
        dataIndex: null,
        render: (_, productAttribute) => (
          <Space>
            <Tooltip title='Cập nhật'>
              <Button
                size='large'
                className='button-color-theme'
                type='primary'
                shape='circle'
                icon={<EditOutlined />}
                onClick={() => openupdateProductAttributeDialog(productAttribute)}
              />
            </Tooltip>
            <Tooltip title='Vô hiệu'>
              <Button
                danger
                size='large'
                type='primary'
                shape='circle'
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteProductType(productAttribute?._id)}
              />
            </Tooltip>
          </Space>
        ),
        align: 'center',
      },
    ];
  }, []);
  const columnsDeleted = useMemo(() => {
    return [
      {
        key: '1',
        title: '#',
        dataIndex: '_id',
        render: (value) => value.slice(-7, -1),
        align: 'center',
      },
      // {
      //   key: '2',
      //   title: 'thành phần sản phẩm',
      //   dataIndex: 'imageUrl',
      //   render: (value) => (
      //     <Image src={value} style={{ width: 120, height: 120, objectFit: 'contain' }} />
      //   ),
      //   align: 'center',
      // },
      {
        key: '3',
        title: 'Tên',
        dataIndex: 'name',
        render: (value) => <span className='text-capitalize'>{value}</span>,
      },
      {
        key: '4',
        title: null,
        dataIndex: null,
        render: (_, productAttribute) => (
          <Space>
            <Tooltip title='Bỏ vô hiệu'>
              <Button
                size='large'
                type='primary'
                shape='circle'
                icon={<CheckOutlined />}
                onClick={() => handleActiveProductAtribute(productAttribute?._id)}
              />
            </Tooltip>
          </Space>
        ),
        align: 'center',
      },
    ];
  }, []);

  const items = [
    {
      key: "1",
      label: "Hiệu lực",
      // children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Vô Hiệu",
      // children: 'Content of Tab Pane 2',
    },
  ];
  const onChange = (key) => {
    setActiveTab(key);
  };
  useEffect(() => {
    
    getProductAttribute();
    getProductAttributeDeleted();
  }, []);

  return (
    <>
      <div className='py-2'>
        {contextHolder}
        <Button size='large' className='button-color-theme' type='primary' icon={<PlusOutlined />} onClick={() => setIsOpen(true)}>
          Thêm mới
        </Button>
      </div>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      {activeTab === "1" && (
              <LtDynamicTable cols={columns} dataSrc={ProductAttribute} rowKey='_id' />

      )}
      {activeTab === "2" && (
              <LtDynamicTable cols={columnsDeleted} dataSrc={ProductAttributeDeleted} rowKey='_id' />

      )}
      <LtFormModal
        isOpen={isOpen}
        title={isUpdating ? 'CẬP NHẬT thành phần SẢN PHẨM' : 'THÊM thành phần SẢN PHẨM'}
        onCancel={handleCloseModal}
        okBtnText={isUpdating ? 'Cập nhật' : 'Thêm'}
        cancelBtnText='Huỷ'
        onSubmit={handleSubmit(handleSubmitProductTypeForm)}>
        <Form name='my-add-product-type-form' layout='vertical'>
          <LtFormInput
            label='Tên thành phần sản phẩm'
            name='name'
            control={control}
            error={errors?.name}
            placeholder='Nhập thành phần sản phẩm'
            rules={{ required: 'Tên không được để trống' }}
          />
          {/* {imageUrl && (
            <div className='text-center py-2'>
              <Image src={imageUrl} style={{ width: 120, height: 120, objectFit: 'contain' }} />
            </div>
          )} */}
          {/* <div className='pt-3 text-center'>
            <Upload
              name='avatar'
              onRemove={revokeImageUrl}
              beforeUpload={() => false}
              onChange={handleUploadImageFile}
              showUploadList={false}>
              <Button size='large' icon={<UploadOutlined />}>
                Tải ảnh lên
              </Button>
            </Upload>
          </div> */}
        </Form>
      </LtFormModal>
    </>
  );
};

export default ProductAttribute;
