import {
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Space, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const CommentItem = ({ comment, currentUser, onDelete, onUpdate }) => {
  const [t] = useTranslation("global");

  const handleDeleteComment = () => {
    onDelete(comment?._id);
  };

  const handleUpdateComment = () => {
    onUpdate(comment);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center justify-content-start">
          <Avatar
            src={comment?.user?.avatar}
            icon={<UserOutlined />}
            size={48}
          />
          <div className="ml-3">
            <h6>{comment?.user?.name}</h6>
            <span>{moment(moment.createdAt).format("HH:ss DD/MM/YYYY")}</span>
            {comment?.star && <span> (đã mua sản phẩm)</span>}
          </div>
        </div>
        {(currentUser?.id === comment?.user?._id || currentUser?.isAdmin) && (
          <Space>
            {currentUser?.id === comment.user?._id && (
              <Tooltip title={t("CommentItem.title1")}>
                <Button
                  size="large"
                  type="primary"
                  shape="circle"
                  className="button-color-theme"
                  icon={<EditOutlined />}
                  onClick={handleUpdateComment}
                />
              </Tooltip>
            )}
            <Tooltip title={t("CommentItem.title1")}>
              <Button
                danger
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={handleDeleteComment}
              />
            </Tooltip>
          </Space>
        )}
      </div>
      {[...Array(Math.floor(comment.star))].map((_, index) => (
        <StarOutlined />
      ))}
      <p className="mt-2">{comment.content}</p>
      <br />
    </>
  );
};

export default CommentItem;
