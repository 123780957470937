import { ShoppingCartOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Carousel, Empty, Form, InputNumber, message, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ProductCard from '../../core/components/product-card';
import CommentsService from '../../shared/services/comments.service';
import { productService } from '../../shared/services/products.service';
import { actions, selectors } from '../../stores';
import CommentItem from './comment-item/CommentItem';
import Comment from './comments/Comments';
import { v4 as uuid } from "uuid";
import './style.scss';
import { useTranslation } from "react-i18next";


const contentStyle = {
  display: 'block',
  height: '600px',
  width: '100%',
  objectFit: 'contain',
};

const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, (index + 1) * size),
  );
};

const Detail = () => {
  const [t] = useTranslation("global");

  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(useSelector(selectors.selectProducts));
  const [comments, setComments] = useState([]);
  const [relevantProducts, setRelevantProducts] = useState([]);
  const userData = useSelector(selectors.selectUserInfo);

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 1,
    },
  });

  const getProductDetail = async () => {
    try {
      dispatch(actions.showLoading());
      const product = await productService.getProductById(id);
      setProduct(product);
      
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const getRelevantProducts = async () => {
    try {
      dispatch(actions.showLoading());
      const relevantProducts = await productService.getRelevantProducts(id);
      setRelevantProducts(relevantProducts);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const getCommentsOfProduct = async () => {
    try {
      dispatch(actions.showLoading());
      const comments = await CommentsService.getCommentsByProductId(id);
      setComments(comments);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const handleAddToCart = async (formValue) => {
    
    const payload = [
      {
        productId: product?._id,
        amount: formValue.amount,
        image: product?.image[0],
        productName: product?.name,
        price: product?.price,
        totalPrice: product?.price * formValue.amount,
      },
    ];
    if (!userData) {
      // New unique id
    // const unique_id = uuid();
    // const randomId = unique_id.slice(0, 16);
    // localStorage.setItem('randomId', randomId);
      // localStorage.setItem('backToUrl', window.location.pathname);
      // messageApi.warning('Bạn chưa đăng nhập');
      // return navigate('/dang-nhap');
      const existingProductIndex = products.findIndex((item) => item.productId === product?._id);
      if (existingProductIndex !== -1) {
        const updatedProducts = [...products];
        updatedProducts[existingProductIndex].amount += formValue.amount;
        updatedProducts[existingProductIndex].totalPrice += product?.price * formValue.amount;
        setProducts(updatedProducts);
        const res = await dispatch(actions.addProductToCartGuest(updatedProducts));
      } else {
        const updatedCart = [...products, ...payload];
        setProducts(updatedCart);
        const res = await dispatch(actions.addProductToCartGuest(updatedCart));
      }
      // const res = await dispatch(actions.addProductToCartGuest(Cart));
    }else{
      const res = await dispatch(actions.addProductToCart(userData.id, payload));
      if (res) {
        messageApi.success(t("Detail.noti1"));
        reset({ amount: 1 });
      }
    }
  };

  const handleAddComment = async (formValue) => {
    try {
      dispatch(actions.showLoading());
      await CommentsService.create({
        productId: product?._id,
        userId: userData.id,
        content: formValue.content,
      });
      getCommentsOfProduct();
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const handleUpdateComment = async (comment) => {
    const { value: text, isConfirmed } = await Swal.fire({
      input: 'textarea',
      inputValue: comment.content,
      inputLabel: t("Detail.label1"),
      inputPlaceholder: t("Detail.placeholder"),
      showCancelButton: true,
      cancelButtonText: t("Detail.button1"),
      confirmButtonText: t("Detail.button2"),
      inputValidator: (value) => {
        if (!value.trim()) {
          return t("Detail.warning2");
        }
      },
    });
    if (isConfirmed) {
      try {
        dispatch(actions.showLoading());
        await CommentsService.update(comment?._id, { content: text });
        getCommentsOfProduct();
      } catch (error) {
        messageApi.error(error?.response?.data?.message || error.message);
      } finally {
        dispatch(actions.hideLoading());
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      dispatch(actions.showLoading());
      await CommentsService.remove(commentId);
      getCommentsOfProduct();
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getProductDetail();
    getRelevantProducts();
    getCommentsOfProduct();
  }, [id]);

  return (
    <>
      {contextHolder}
      <div className='container-fluid p-5'>
        {product ? (
          <>
            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <Carousel autoplay className='bg-light'>
                  {product.image.map((imageUrl) => (
                    <div key={imageUrl}>
                      <img style={contentStyle} src={imageUrl} alt='Carousel' />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className='col-md-6 col-xs-12'>
                <h1>{product?.name}</h1>
                <hr />
                <div className='detail-item'>
                  <span className='detail-item-title'>{t("Detail.title1")} </span>
                  <span>{product.type?.name}</span>
                </div>
                <div className='detail-item'>
                  <span className='detail-item-title'>{t("Detail.title2")} </span>
                  {product.attributes.map((attribute, index) => (
                    <span key={index}>{attribute?.name},</span>
                  ))}
                </div>
                <div className='detail-item'>
                  <span className='detail-item-title'>{t("Detail.title3")} </span>
                  <span>{product.countInStock}</span>
                </div>
                <div className='detail-item'>
                  <span className='detail-item-title'>{t("Detail.title4")} </span>
                  <NumericFormat value={product.price} displayType='text' thousandSeparator=',' />
                </div>
                <div className='detail-item'>
                  <span className='detail-item-title'>Điểm đánh giá: </span>
                  <Tooltip title ={product.rating.$numberDecimal}>
                  {[...Array(Math.floor(product.rating.$numberDecimal))].map((_, index) => (
                     <StarOutlined />
                    ))}
                  </Tooltip>

                </div>
                <Form layout='vertical' onFinish={handleSubmit(handleAddToCart)}>
                  <div className='d-flex'>
                    <Form.Item
                      label= {t("Detail.label2")}
                      validateStatus={errors && errors.amount ? 'error' : ''}
                      help={errors && errors.amount && errors.amount.message}
                      className='my-3'>
                      <Controller
                        name='amount'
                        control={control}
                        rules={{
                          required: t("Detail.warning3"),
                          validate: (value) => {
                            if (!value || +value < 1) {
                              return t("Detail.warning4");
                            }
                          },
                        }}
                        render={({ field }) => (
                          <InputNumber {...field} size='large' min={1} max={product.countInStock} />
                        )}
                      />
                    </Form.Item>
                    <Button
                      size='large'
                      icon={<ShoppingCartOutlined />}
                      type='primary'
                      className='ml-3'
                      style={{ alignSelf: 'center', marginTop: 28 }}
                      htmlType='submit'>
                      {t("Detail.button3")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className='py-4'>
              <h3 className='text-uppercase'>{t("Detail.h1")}</h3>
              <hr />
              <p>{product.description}</p>
            </div>
            <div className='py-4'>
              <h3 className='text-uppercase'>{t("Detail.h2")}</h3>
              <hr />
              <div className='py-2'>
                <Carousel autoplay autoplaySpeed={5000}>
                  {chunkArray(relevantProducts, 4).map((productSet, index) => (
                    <div className='list-relevant-products' key={index}>
                      {productSet.map((product) => (
                        <div className='list-relevant-item' key={product?._id}>
                          <ProductCard key={product?._id} product={product} />
                        </div>
                      ))}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className='py-4'>
              <h3 className='text-uppercase'>{t("Detail.h3")}</h3>
              <hr />
              {userData && (
                <>
                  <Comment onAddComment={handleAddComment} />
                </>
              )}
              {comments && comments.length ? (
                <div className='pt-4'>
                  {comments.map((comment) => (
                    <CommentItem
                      key={comment?._id}
                      comment={comment}
                      currentUser={userData}
                      onDelete={handleDeleteComment}
                      onUpdate={handleUpdateComment}
                    />
                  ))}
                </div>
              ) : (
                <div className='text-center'>
                  <Empty description={t("Detail.description1")}/>
                </div>
              )}
            </div>
          </>
        ) : (
          <div>
            <Empty description={t("Detail.description2")}/>
          </div>
        )}
      </div>
    </>
  );
};

export default Detail;
