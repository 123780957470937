import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppContainer from "./AppContainer";
import global_en from "./b2c/core/translations/en/global.json";
import global_vi from "./b2c/core/translations/vi/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import AppB2C from "./AppB2C";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./b2c/stores";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    vi: {
      global: global_vi,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <BrowserRouter>
  //  <React.StrictMode>
  //   <Provider store={store}>
  //     <PersistGate persistor={persistor}>
  //         <AppB2C/>
  //     </PersistGate>
  //   </Provider>
  // </React.StrictMode>
  // </BrowserRouter>

  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      {/* <Provider store={store}> */}
      {/* <Provider> */}
      {/* <PersistGate> */}
      {/* <PersistGate persistor={persistor}> */}
      <AppContainer />
      {/* </PersistGate> */}
      {/* </Provider> */}
    </I18nextProvider>
  </BrowserRouter>
);

reportWebVitals();
