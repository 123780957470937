import { Link } from "react-router-dom";
import { CiEdit, CiLocationOn } from "react-icons/ci";
import { GrContactInfo } from "react-icons/gr";
import { useState } from "react";
import ProfileUpdateModal from "../modals/ProfileUpdateModal";
import Tooltip from "../shared/Tooltip";
import { useTranslation } from "react-i18next";


const OwnProfileCard = ({ user }) => {
  const [t, i18n] = useTranslation("global");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="rounded-md border bg-white p-6">
      <div
        className="flex cursor-pointer justify-end text-xl"
        onClick={handleOpenModal}
      >
        <Tooltip text={t("OProfile.p1")}>
          <CiEdit />
        </Tooltip>
      </div>
      <div className="flex flex-col items-center justify-between">
        <div className="flex flex-col items-center justify-center">
          <div className="">

            <img
              className="mr-0 h-28 w-28 rounded-full object-cover"
              src={user?.avatar}
              alt={t("OProfile.p2")}
            ></img>

            <ProfileUpdateModal
              user={user}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
            />
          </div>

          {/* <div>
            <h2 className="mt-5 text-center text-lg font-bold">{user?.name}</h2>
            {user.bio ? (
              <p className="flex items-center justify-center gap-2 text-gray-600">
                <GrContactInfo className="text-gray-500" />
                {user.bio}
              </p>
            ) : (
              <p className="flex items-center justify-center gap-2 text-gray-400">
                <GrContactInfo className="text-gray-500" />
                {t("OProfile.p3")}
              </p>
            )}
            <hr className="mt-3" />
          </div> */}
        </div>
      </div>

      <div className="my-3 flex flex-col justify-start">
        <p className="font-semibold">{t("OProfile.p4")}</p>
        {user.address ? (
          <p className="flex items-center gap-2 text-gray-700">
            <CiLocationOn className="font-semibold" />
            {user.address}
          </p>
        ) : (
          <p className="flex items-center gap-2 text-gray-400">
            <CiLocationOn className="text-lg font-semibold" />
            {t("OProfile.p5")}
          </p>
        )}
      </div>
    </div>
  );
};

export default OwnProfileCard;
