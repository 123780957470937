import './AppB2C.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';

// Layout
import LazyComponent from './b2c/core/components/lazy-component';
import AdminLayout from './b2c/core/layouts/admin-layout';
import LoadingSpinner from './b2c/core/layouts/loading-spinner';
import NonAuth from './b2c/core/layouts/non-auth';

// Admin
import Carousels from './b2c/pages/admin/carousels';
import Coupons from './b2c/pages/admin/coupons';
import Overview from './b2c/pages/admin/dashboard';
import Orders from './b2c/pages/admin/orders';
import ProductTypes from './b2c/pages/admin/product-types';
import ProductAttribute from './b2c/pages/admin/product-attribute';
import Products from './b2c/pages/admin/products';
import Communities from './b2c/pages/admin/Communities';
import UpdateProduct from './b2c/pages/admin/update-product';
import UpdateCommunity from './b2c/pages/admin/update-community';
import Users from './b2c/pages/admin/users';

// General
import Login from './b2c/pages/auth/login/Login';
import Register from './b2c/pages/auth/register/Register';
import Detail from './b2c/pages/detail';
import ForgotPassword from './b2c/pages/forgot-password/ForgotPassword';
import Home from './b2c/pages/home';
import Information from './b2c/pages/information';
import ChangePassword from './b2c/pages/change-password';
import Intro from './b2c/pages/intro';
import MyCart from './b2c/pages/my-cart';
import Order from './b2c/pages/order';
import OrderPayment from './b2c/pages/order-payment';
import Store from './b2c/pages/store';

// Permissions
import AdminPermission from './b2c/core/permissions/AdminPermission';
import LoggedInPermission from './b2c/core/permissions/LoggedInPermisioin';
import NotLoggedInPermission from './b2c/core/permissions/NotLoggedInPermission';

const AppB2C = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <NonAuth />,
      children: [
        {
          path: '',
          index: true,
          element: <Home />,
        },
        {
          path: 'dang-nhap',
          element: (
            <LazyComponent
              component={
                <NotLoggedInPermission>
                  <Login />
                </NotLoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'dang-ky',
          element: (
            <LazyComponent
              component={
                <NotLoggedInPermission>
                  <Register />
                </NotLoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'thong-tin-ca-nhan',
          element: (
            <LazyComponent
              component={
                <LoggedInPermission>
                  <Information />
                </LoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'doi-mat-khau',
          element: (
            <LazyComponent
              component={
                <LoggedInPermission>
                  <ChangePassword />
                </LoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'quen-mat-khau',
          element: (
            <LazyComponent
              component={
                <NotLoggedInPermission>
                  <ForgotPassword />
                </NotLoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'cua-hang',
          element: <LazyComponent component={<Store />} />,
        },
        {
          path: 've-chung-toi',
          element: <LazyComponent component={<Intro />} />,
        },
        {
          path: 'don-hang-cua-toi',
          element: (
            <LazyComponent
              component={
                <LoggedInPermission>
                  <Order />
                </LoggedInPermission>
              }
            />
          ),
        },
        {
          path: 'gio-hang-cua-toi',
          element: (
            <LazyComponent
              component={
                  <MyCart />
              }
            />
          ),
        },
        {
          path: 'thanh-toan-don-hang',
          element: (
            <LazyComponent
              component={
                  <OrderPayment />
              }
            />
          ),
        },
        {
          path: 'chi-tiet-san-pham/:id',
          element: <LazyComponent component={<Detail />} />,
        },
      ],
    },
    {
      path: '/admin',
      element: (
        <AdminPermission>
          <AdminLayout />
        </AdminPermission>
      ),
      children: [
        {
          path: '',
          index: true,
          element: <LazyComponent component={<Overview />} />,
        },
        {
          path: 'tai-khoan',
          element: <LazyComponent component={<Users />} />,
        },
        {
          path: 'banner',
          element: <LazyComponent component={<Carousels />} />,
        },
        {
          path: 'khuyen-mai',
          element: <LazyComponent component={<Coupons />} />,
        },
        {
          path: 'don-hang',
          element: <LazyComponent component={<Orders />} />,
        },
        {
          path: 'cap-nhat-san-pham/:productId',
          element: <LazyComponent component={<UpdateProduct />} />,
        },
        {
          path: 'san-pham',
          element: <LazyComponent component={<Products />} />,
        }, {
          path: 'cong-dong',
          element: <LazyComponent component={<Communities />} />,
        },
        {
          path: 'cap-nhat-cong-dong/:commnuityId',
          element: <LazyComponent component={<UpdateCommunity />} />,
        },
        {
          path: 'loai-san-pham',
          element: <LazyComponent component={<ProductTypes />} />,
        },
        {
          path: 'thuoc-tinh-san-pham',
          element: <LazyComponent component={<ProductAttribute />} />,
        },
      ],
    },
  ]);

  return (
    <>
      {routes}
      <LoadingSpinner />
    </>
  );
};

export default AppB2C;
