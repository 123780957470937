import React from "react";
import "./style.scss";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../shared/services/auth-service";
import { useDispatch } from "react-redux";
import { actions } from "../../../stores";
import { useForm } from "react-hook-form";
import LtFormInput from "../../../core/components/lt-form-input";
import * as types from "../../../../community/redux/constants/authConstants";
import Swal from 'sweetalert2';

import { useTranslation } from "react-i18next";

export default function Register() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      name: "",
      phone: "",
      address: "",
    },
  });

  const handleRegister = async (values) => {
    try {
      dispatch(actions.showLoading());
      delete values.passwordConfirm;
      const user = await authService.signUp(values);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("profile", JSON.stringify(user));
      // dispatch(actions.setUser(user));
      dispatch({
        type: types.SIGNIN_SUCCESS,
        payload: user,
      });
      messageApi.open({
        type: "success",
        content: t("signup.noti1"),
      });
      Swal.fire({
        title: "Đăng ký thành công, vui lòng xác thực email",
        confirmButtonText: "Đồng ý",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          return navigate("/");
        } else if (result.isDenied) {
          return navigate("/");
        }else{
          return navigate("/");
        }
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || error.message,
      });
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  return (
    <div className="register layout-height py-3">
      {contextHolder}
      <div className="register-container">
        <div className="register-title">
          <h2
            className="title"
            style={{ borderTopLeftRadius: "10px" }}
            onClick={() => navigate("/dang-nhap")}
          >
            {t("signup.signin")}
          </h2>
          <h2 className="title active" style={{ borderTopRightRadius: "10px" }}>
            {t("signup.signup")}
          </h2>
        </div>
        <div className="register-form">
          <Form layout="vertical" onFinish={handleSubmit(handleRegister)}>
            <LtFormInput
              name="email"
              label={t("signup.label1")}
              control={control}
              error={errors.email}
              placeholder={t("signup.ip1")}
              rules={{
                required: t("signup.not1"),
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: t("signup.wwar1"),
                },
              }}
            />
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <LtFormInput
                  isPassword
                  name="password"
                  label={t("signup.label2")}
                  control={control}
                  error={errors.password}
                  placeholder={t("signup.ip2")}
                  rules={{
                    required: t("signup.not1"),
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@!#$%^&*])[A-Za-z\d@!#$%^&*]{8,}$/,
                      message: t("signup.not2"),
                    },
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <LtFormInput
                  control={control}
                  isPassword
                  name="passwordConfirm"
                  label={t("signup.label3")}
                  error={errors.passwordConfirm}
                  placeholder={t("signup.ip3")}
                  rules={{
                    required: t("signup.not1"),
                    validate: (value) =>
                      value === watch("password") || t("signup.war2"),
                  }}
                />
              </div>
            </div>

            <LtFormInput
              name="name"
              label={t("signup.label4")}
              control={control}
              error={errors?.name}
              placeholder={t("signup.ip4")}
              rules={{ required: t("signup.not1") }}
            />
            <LtFormInput
              name="phone"
              label={t("signup.label5")}
              control={control}
              error={errors.phone}
              placeholder={t("signup.ip5")}
              rules={{
                required: t("signup.not1"),
                pattern: {
                  value: /^(\+84|0)(3|5|7|8|9)(\d{8})$/,
                  message: t("signup.war3"),
                },
              }}
            />
            <LtFormInput
              name="address"
              label={t("signup.label6")}
              control={control}
              placeholder={t("signup.ip6")}
            />
            <div className="form-group">
              <Button
                size="large"
                type="primary"
                className="w-100 button-color-theme"
                htmlType="submit"
              >
                {t("signup.signup")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
