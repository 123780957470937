import { useSelector } from "react-redux";

import UserProfile from "../components/profile/UserProfile";
import { selectors } from '../../b2c/stores';

const Profile = () => {
  const userData = useSelector(selectors.selectUserInfo);
  // const userData = useSelector((state) => state.auth?.userData);

  return (
    <div className="main-section">
      <UserProfile userData={userData} />
    </div>
  );
};

export default Profile;
