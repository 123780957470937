import axiosClient from '../interceptors';

export const StatisticService = {
  getStatistics: async (filterOptions) => {
    const { data: responseResults } = await axiosClient.get('/api/statistic/CalculateStatistics', {
      params: filterOptions,
    });
    return responseResults.data;
  },
  getLiked: async (filterOptions) => {
    const { data: responseResults } = await axiosClient.get('/api/statistic/likedService');
    return responseResults.data;
  },
  getUsers: async () => {
    const { data: responseResults } = await axiosClient.get('/api/statistic/users');
    
    return responseResults.data;
  },
  intialOverview: async () => {
    try {
      const { data: responseResults } = await axiosClient.get('/api/statistic/intialOverview');
      return responseResults.data;
    } catch (error) {
      throw error;
    }
  },
  revenueProductEachMonth: async () => {
    try {
      const { data: responseResults } = await axiosClient.get('/api/statistic/revenueProductEachMonth');
      return responseResults.data;
    } catch (error) {
      throw error;
    }
  }
};