import { Avatar, Badge, Button, Dropdown, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import BagIcon from "../../../assets/icon/bagIcon.svg";
import LogoutIcon from "../../../assets/icon/logoutIcon.svg";
import NoteIcon from "../../../assets/icon/noteIcon.svg";
import SearchIcon from "../../../assets/icon/searchIcon.svg";
import GroupIcon from "../../../assets/icon/groupUser.svg";
import LanguageIcon from "../../../assets/icon/language.png";
import AvatarIcon from "../../../assets/img/avatar-2.png";
import Logo from "../../../assets/img/logo.svg";
import { actions, selectors } from "../../../stores";
import "./style.scss";
import { UserOutlined, LoginOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";

const Header = () => {
  const [t, i18n] = useTranslation("global");
  const [language, setLanguage] = useState(i18n.language || "vi"); // Lưu trữ ngôn ngữ hiện tại trong state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectors.selectUserInfo);
  const products = useSelector(selectors.selectProducts);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Thay đổi ngôn ngữ
    setLanguage(lang); // Cập nhật state language
  };

  const [searchValue, setSearchValue] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      performSearch();
    }
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const performSearch = () => {
    navigate(`/cua-hang?name=${searchValue}`);
  };

  const handleIconClick = () => {
    performSearch();
  };
  const handleNavigate = (direction) => {
    navigate(direction);
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(actions.resetUser());
    dispatch(actions.setCartProducts([]));
    return handleNavigate("/");
  };
  // const items = useMemo(() => {
  //   return [
  //     {
  //       key: "1",
  //       label: (
  //         <div
  //           onClick={() => {
  //             return handleNavigate("/thong-tin-ca-nhan");
  //           }}
  //         >
  //           {t("header.dropdown1")}
  //         </div>
  //       ),
  //     },
  //     {
  //       key: "2",
  //       label: (
  //         <div
  //           onClick={() => {
  //             return handleNavigate("/doi-mat-khau");
  //           }}
  //         >
  //           {t("header.dropdown2")}
  //         </div>
  //       ),
  //     },
  //     {
  //       key: "3",
  //       label: (
  //         <div
  //           onClick={() => {
  //             localStorage.removeItem("user");
  //             dispatch(actions.resetUser());
  //             dispatch(actions.setCartProducts([]));
  //             return handleNavigate("/");
  //           }}
  //         >
  //           {t("header.dropdown3")}
  //         </div>
  //       ),
  //     },
  //   ];
  // }, []);

  const getDropdownItems = () => {
    return [
      {
        key: "1",
        label: (
          <div
            onClick={() => {
              handleNavigate("/thong-tin-ca-nhan");
            }}
          >
            {t("header.dropdown1")}
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            onClick={() => {
              handleNavigate("/doi-mat-khau");
            }}
          >
            {t("header.dropdown2")}
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div
            onClick={() => {
              handleLogout();
            }}
          >
            {t("header.dropdown3")}
          </div>
        ),
      },
    ];
  };


  return (
    <>
      <header className="sticky-top">
        <NavLink to="/">
          <img src={Logo} alt="logo" style={{ height: "80px" }} />
        </NavLink>
        <div className="header-container">
          <div className="up">
            <button
              class="button-89"
              role="button"
              type="primary"
              size="large"
              onClick={() => handleNavigate("/")}
            >
              {t("header.tab1")}
            </button>
            <button
              class="button-89"
              role="button"
              type="primary"
              size="large"
              onClick={() => handleNavigate("/cua-hang")}
            >
              {t("header.tab2")}
            </button>
            <button
              class="button-89"
              role="button"
              type="primary"
              size="large"
              onClick={() => handleNavigate("/ve-chung-toi")}
            >
              {t("header.tab3")}
            </button>

            <div className="input">
              <input
                style={{ margin: "0px" }}
                placeholder={t("header.note")}
                value={searchValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <button
                className="incon-search"
                style={{ margin: "0px" }}
                onClick={handleIconClick}
              >
                <img src={SearchIcon} alt="logo" />
              </button>
            </div>
            

            {/* <div className="mb-2 flex justify-end">
                  <div
                    className="flex h-fit items-center gap-2 rounded-xl border border-none border-gray-300 p-2 outline-none"
                    style={{
                      width: "min-content",
                      marginTop: "10px",
                    }}
                  >
                    <svg
                      width={24}
                      height={24}
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                    </svg>
                    <select
                      name=""
                      className="border-none bg-transparent outline-none"
                      onChange={handleChangeLanguage} 
                    >
                      <option value="en" className="bg-black text-base text-white">
                        EN
                      </option>
                      <option value="vi" className="bg-black text-base text-white">
                        VI
                      </option>
                    </select>
                  </div>
            </div> */}

            {userData?.isAdmin ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column", // Hiển thị theo cột
                  }}
                >
                  <h2 className="text-uppercase text-center">
                    Đăng xuất tài khoản Admin nhé!
                  </h2>
                  <Button
                    type="primary"
                    danger
                    size="large"
                    onClick={handleLogout}
                  >
                    <LoginOutlined /> Đăng xuất
                  </Button>
                </div>
              </>
            ) : (
              <>
                {" "}
                {!userData ? (
                  <>
                  <Tooltip title="Giỏ hàng">
                      <Badge
                        count={
                          products && products.length ? products.length : null
                        }
                      >
                        <div
                          className="icon-up"
                          onClick={() => handleNavigate("/gio-hang-cua-toi")}
                        >
                          <img src={BagIcon} alt="logo" />
                        </div>
                      </Badge>
                    </Tooltip>
                    <Tooltip title={t("header.sig")}>
                      <div
                        className="icon-up"
                        onClick={() => handleNavigate("/dang-nhap")}
                      >
                        <img src={LogoutIcon} alt="logo" />
                      </div>
                    </Tooltip>
                    <button
                      onClick={() => handleChangeLanguage(language === "en" ? "vi" : "en")}
                      className={`flex items-center justify-center px-3 py-6 transition-all rounded-full ${
                        language === "vi" ? "text-white" : "text-black"
                      }`}
                      style={{
                        fontSize: '20px',
                        backgroundColor: 'transparent', // Ensuring no background color
                        border: 'none', // Removed the border
                        marginTop: '-2px', 
                      }}
                    >
                      {/* Use the Flag component with the country code */}
                      <Flag code={language === "en" ? "GB" : "VN"} style={{ width: '30px', height: '20px' }} /> 
                    </button>
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={t("header.tooltip1")}
                      onClick={() => handleNavigate("/com/home")}
                    >
                      <div className="icon-up">
                        <img src={GroupIcon} alt="logo" />
                      </div>
                    </Tooltip>

                    <Tooltip title={t("header.tooltip2")}>
                      <Badge
                        count={
                          products && products.length ? products.length : null
                        }
                      >
                        <div
                          className="icon-up"
                          onClick={() => handleNavigate("/gio-hang-cua-toi")}
                        >
                          <img src={BagIcon} alt="logo" />
                        </div>
                      </Badge>
                    </Tooltip>
                    <Tooltip
                      title={t("header.tooltip3")}
                      onClick={() => handleNavigate("/don-hang-cua-toi")}
                    >
                      <div className="icon-up">
                        <img src={NoteIcon} alt="logo" />
                      </div>
                    </Tooltip>
                    <div className="icon-up">
                    <Dropdown
                      menu={{
                        items: getDropdownItems(),
                      }}
                      placement="bottom"
                      arrow
                    >
                        <Avatar
                          src={userData?.avatar}
                          size="large"
                          icon={<UserOutlined />}
                        />
                      </Dropdown>
                    </div>
                    <button
                      onClick={() => handleChangeLanguage(language === "en" ? "vi" : "en")}
                      className={`flex items-center justify-center px-3 py-6 transition-all rounded-full ${
                        language === "vi" ? "text-white" : "text-black"
                      }`}
                      style={{
                        fontSize: '20px',
                        backgroundColor: 'transparent', // Ensuring no background color
                        border: 'none', // Removed the border
                        marginTop: '-2px', 
                      }}
                    >
                      {/* Use the Flag component with the country code */}
                      <Flag code={language === "en" ? "GB" : "VN"} style={{ width: '30px', height: '20px' }} /> 
                    </button>

                  </>
                )}
              </>
            )}
          </div>

          {/* 
          <div className="down">
            
          </div> */}
        </div>
      </header>
    </>
  );
};

export default Header;
