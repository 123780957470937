import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Form, message } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LtFormInput from '../../core/components/lt-form-input';
import { actions, selectors } from '../../stores';
import './style.scss';
import { authService } from '../../shared/services/auth-service';
import { useTranslation } from "react-i18next";


export default function ForgotPassword() {
  const [t] = useTranslation("global");

  const [isResend, setIsResend] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.selectIsLoading);
  const [messageApi, contextHolder] = message.useMessage();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: '' } });

  const handleResetPassword = async (values) => {
    try {
      setIsResend(false);
      dispatch(actions.showLoading());
      await authService.forgotPass(values);
      setIsResend(true);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  return (
    <div className='forgot-password layout-height'>
      {contextHolder}
      <div className='forgot-password-container'>
        <div className='forgot-password-title'>
          <h2 className='title active'>{t("ForgotPass.title1")}</h2>
        </div>
        <div className='forgot-password-form'>
          <Form layout='vertical' onFinish={handleSubmit(handleResetPassword)}>
            <LtFormInput
              label='Email'
              name='email'
              control={control}
              error={errors.email}
              placeholder={t("ForgotPass.placholder1")}
              rules={{
                required: t("ForgotPass.warning1"),
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: t("ForgotPass.warning2"),
                },
              }}
            />
            {isResend && (
              <div className='pb-3'>
                <Alert
                  type='success'
                  message={t("ForgotPass.warning3")}
                />
              </div>
            )}
            {!isLoading && (
              <Button size='large' type='primary' className='w-100 button-color-theme' htmlType='submit'>
                {isResend ? t("ForgotPass.warning4") : t("ForgotPass.warning5")}
              </Button>
            )}
          </Form>
          <hr />
          <div className='pb-3'>
            <NavLink to='/dang-nhap'>
              <Button size='large' icon={<ArrowLeftOutlined />} className='button-margin-top-8 '>
                {t("ForgotPass.button")}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
