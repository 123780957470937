// import { persistor, store } from "./community/redux/store";
import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from '../src/b2c/stores';
import { PersistGate } from "redux-persist/integration/react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// import createAppStore from "../src/community/redux/store";
import axios from "axios";
import CommonLoading from "../src/community/components/loader/CommonLoading";

import AppGateWay from "./AppGateWay";
const ErrorComponent = ({ errorMessage }) => (
  <div className="text-center font-bold text-red-500">{errorMessage}</div>
);
const AppContainer = () => {
  const location = useLocation();
  // const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        await axios.get("/server-status");
      } catch (err) {
        setError("Server is down. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    checkServerStatus();
  }, []);

  // Asynchronously initialize the Redux store, including data fetching and authentication,
  // while displaying a loading indicator. Making sure that the store is initialized with credentials and data before rendering the app.

  // useEffect(() => {
  //   const initializeStore = async () => {
  //     try {
  //       const appStore = await createAppStore();
  //       setStore(appStore);
  //     } catch (err) {
  //       setError(`Error initializing the app: ${err.message}`);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   initializeStore();
  // }, []);

  if (loading || error) {
    return (
      <div className="flex h-screen items-center justify-center">
        {loading ? <CommonLoading /> : <ErrorComponent errorMessage={error} />}
      </div>
    );
  } else {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppGateWay />
        </PersistGate>
      </Provider>
    );
  }
};

export default AppContainer;
