
import AppB2c from "./AppB2C";
import AppCOM from "./AppCOM";
import { useLocation } from "react-router-dom";
import { getTitleFromRoute } from "../src/community/utils/docTitle";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { persistor, store } from "./b2c/stores";
import { PersistGate } from "redux-persist/lib/integration/react";
import createAppStore from "./community/redux/store";
import { useState, useEffect } from "react";


const AppGateWay = () => {
  const location = useLocation();
  const [appStore, setAppStore] = useState(null);

  useEffect(() => {
    const initializeStore = async () => {
      try {
        const appStore = await createAppStore();
        setAppStore(appStore);
      } catch (err) {

      } finally {

      }
    };

    initializeStore();
  }, []);

  if (location.pathname.startsWith("/com")) {
    return (
      <>
        {/* <Provider store={store}>
          <PersistGate persistor={persistor}> */}
        <Helmet>
          <title>{getTitleFromRoute(location.pathname)}</title>
        </Helmet>
        <AppCOM />
        {/* </PersistGate>
        </Provider> */}
      </>
    );
  } else {
    return (
      <>
        {/* <Provider store={store}>
          <PersistGate persistor={persistor}> */}
        <Helmet>
          <title>{getTitleFromRoute(location.pathname)}</title>
        </Helmet>
        <AppB2c />
        {/* </PersistGate>
        </Provider> */}
      </>
    );
  }
};

export default AppGateWay;
