import React from "react";
import "./style.scss";
import LineFullIcon from "../../assets/icon/lineFullIcon.svg";
import img2 from "../../assets/img/img-2.png";
import HeartImg from "../../assets/img/intro.png";
import PurposeImg from "../../assets/img/intro-1.png";
import ItemMember from "./components/ItemMember/ItemMember";
import Avatar1 from "../../assets/img/avatar-1.png";
import Avatar2 from "../../assets/img/avatar-2.png";
import { useTranslation } from "react-i18next";


const Intro = () => {
  const [t, i18n] = useTranslation("global");
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    
    <div className="intro">
      <img className="mb-2" src={img2} alt="line" width="100%" />
      <img className="mb-2" src={LineFullIcon} alt="line" width="100%" />
      <div className="banner">
        <div className="logo">
          <div>
            <span>LT</span>Handmade
          </div>
        </div>
        <div className="slogan">
          <div>
            <div>{t("intro.slogan1")}</div>
            <div>{t("intro.slogan2")}</div>
            <div>{t("intro.slogan3")}</div>
          </div>
        </div>
      </div>
      <img src={LineFullIcon} alt="line" width="100%" />
      <div className="about">
        <div className="left">
          <div>
            <div className="title">{t("intro.title")}</div>
            <div>
            {t("intro.about1")}
            </div>
            <div>
            {t("intro.about2")}
            </div>
            <div>
            {t("intro.about3")}
            </div>
            <div>
            {t("intro.about4")}
            </div>
          </div>
        </div>
        <div className="right">
          <img src={HeartImg} alt="" />
        </div>
      </div>
      <div className="line">
        <hr />
        <div className="circle" />
      </div>
      <div className="purpose">
        <div className="left">
          <img src={PurposeImg} alt="" />
        </div>
        <div className="right">
          <div>
            <div className="title">{t("intro.right1")}</div>
            <div>
            {t("intro.subright1")}
            </div>
            <div className="title">{t("intro.right2")}</div>
            <div>
            {t("intro.subright2")}
            </div>
            <div className="title">{t("intro.right3")}</div>
            <div>
            {t("intro.subright3")}
            </div>
          </div>
        </div>
      </div>
      <img src={LineFullIcon} alt="line" width="100%" />
      <div className="member">
        <ItemMember
          item={{
            id: "20149058",
            img: Avatar1,
            name: "Lê Minh Tài",
            email: "tailx0913@gmail.com",
            class: "20110CLST5",
          }}
        />
        <ItemMember
          item={{
            id: "20110126",
            img: Avatar2,
            name: "Trần Thị Phương Linh",
            email: "phuonglinhtht321@gmail.com",
            class: "20110CLST4",
          }}
        />
      </div>
    </div>
  );
};

export default Intro;
