import { lazy } from "react";

import Home from "../src/community/pages/Home";
import Profile from "../src/community/pages/Profile";
import Post from "../src/community/pages/Post";
import OwnPost from "../src/community/pages/OwnPost";
import CommunityHome from "../src/community/pages/CommunityHome";
import Saved from "../src/community/pages/Saved";
import PublicProfile from "../src/community/pages/PublicProfile";
import AllCommunities from "../src/community/pages/AllCommunities";
import MyCommunities from "../src/community/pages/MyCommunities";
import Following from "../src/community/pages/Following";
import SignUp from "../src/community/pages/SignUp";

const ReportedPost = lazy(() => import("../src/community/pages/ReportedPost"));
const Moderator = lazy(() => import("../src/community/pages/Moderator"));
const DevicesLocations = lazy(() => import("../src/community/pages/DevicesLocations"));
const VerifyEmail = lazy(() => import("../src/community/pages/VerifyEmail"));
const EmailVerifiedMessage = lazy(() => import("../src/community/pages/EmailVerifiedMessage"));
const BlockDevice = lazy(() => import("../src/community/pages/BlockDevice"));
const LoginVerified = lazy(() => import("../src/community/pages/LoginVerified"));
const AccessDenied = lazy(() => import("../src/community/pages/AccessDenied"));
const NotFound = lazy(() => import("../src/community/pages/NotFound"));

export const privateRoutes = [
  {
    path: "/com",
    element: <Home />,
  },
  // {
  //   path: "/com/",
  //   element: <Home />,
  // },
  {
    path: "/com/home",
    element: <Home />,
  },
  {
    path: "/com/profile",
    element: <Profile />,
  },
  {
    path: "/com/home/com/post/:postId",
    element: <Post />,
  },
  {
    path: "/com/community/:communityName/com/post/:postId",
    element: <Post />,
  },
  {
    path: "/com/profile/com/my/post/:postId",
    element: <OwnPost />,
  },
  {
    path: "/com/community/:communityName",
    element: <CommunityHome />,
  },
  {
    path: "/com/community/:communityName/reported-post",
    element: <ReportedPost />,
  },
  {
    path: "/com/community/:communityName/moderator",
    element: <Moderator />,
  },
  {
    path: "/com/saved",
    element: <Saved />,
  },
  {
    path: "/com/user/:userId",
    element: <PublicProfile />,
  },
  {
    path: "/com/communities",
    element: <AllCommunities />,
  },
  {
    path: "/com/my-communities",
    element: <MyCommunities />,
  },
  {
    path: "/com/following",
    element: <Following />,
  },
  {
    path: "/com/devices-locations",
    element: <DevicesLocations />,
  },
];

export const publicRoutes = [
  {
    path: "/com/signup",
    element: <SignUp />,
  },

  {
    path: "/com/auth/verify",
    element: <VerifyEmail />,
  },
  {
    path: "/com/email-verified",
    element: <EmailVerifiedMessage />,
  },
  {
    path: "/com/block-device",
    element: <BlockDevice />,
  },
  {
    path: "/com/verify-login",
    element: <LoginVerified />,
  },
  {
    path: "/com/access-denied",
    element: <AccessDenied />,
  },
  {
    path: "/com/*",
    element: <NotFound />,
  },
];
