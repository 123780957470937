import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// import { tokenMiddleware } from "../middlewares/tokenMiddleware";
import { initializeAuth } from "./actions/authActions";

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as cartSelectors from './selectors/cartSelectors';
import * as globalSelectors from './selectors/globalSelectors';

import * as cartActions from './actions/cartAction';
import * as globalActions from './actions/globalActions';

const middlewares = [thunk];
const createAppStore = async () => {
  try {
    const store = configureStore({
      reducer: rootReducer,
      // middleware: [thunk, tokenMiddleware],
      middleware: middlewares,
    });
    // await store.dispatch(initializeAuth());

    return store;
  } catch (err) {
    throw new Error("Some error occurred");
  }
};
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
export const actions = {
  ...globalActions,
  ...cartActions,
};
export default createAppStore;
export const selectors = {
  ...globalSelectors,
  ...cartSelectors,
};
