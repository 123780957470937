import React from 'react'
import './style.scss'
import { useTranslation } from "react-i18next";

const ItemMember = ({ item }) => {
    const [t, i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    return (
        <div className='item-member'>
            <div className='id'>{item.id}</div>
            <hr />
            <img src={item.img} alt='' />
            <div className='info'>
                <div>{t("ItemMember.title")}</div>
                <div>{item?.name}</div>
            </div>
            <div className='contact'>Email: <span>{item.email}</span></div>
            <div className='contact'>{t("ItemMember.class")}<span>{item.class}</span></div>
            <hr />
        </div>
    )
}
export default ItemMember