import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import {
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
// import faker from 'faker';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import LtFormDatePicker from "../../../core/components/lt-form-date-picker";
import LtFormDropdown from "../../../core/components/lt-form-dropdown";
import { OrderStatus } from "../../../shared/enums/order-status.enum";
import { StatisticService } from "../../../shared/services/statistic.service";
import { actions } from "../../../stores";
import { forEach, set } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const currentYear = new Date().getFullYear();
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Biểu đồ doanh thu các loại sản phẩm năm " + currentYear,
    },
  },
};

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data:  [65, 59, 80, 81, 56, 55, 40],
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: [28, 48, 40, 19, 86, 27, 90],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

function ProductLine(input) {
  
  return (
    <>
      <div
        className="pt-3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "80vh",
        }}
      >
        <Line options={options} data={input.revenueProductType} />;
      </div>
    </>
  );
}
export default ProductLine;
