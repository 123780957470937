/**
 * Project Name: SocialEcho
 * Description: A social networking platform with automated content moderation and context-based authentication system.
 *
 * Author: Neaz Mahmud
 * Email: neaz6160@gmail.com
 * Date: 19th June 2023
 */
import './AppCOM.css';
import { lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import FallbackLoading from "../src/community/components/loader/FallbackLoading";
import { publicRoutes, privateRoutes } from "./routes";
import { signInAction, clearMessage } from "../src/community/redux/actions/authActions";



import PrivateRoute from "./PrivateRoute";
import SignIn from "../src/community/pages/SignIn";
import { selectors } from '../src/b2c/stores';
import { useEffect, useState } from "react";
const AdminPanel = lazy(() => import("../src/community/pages/AdminPanel"));
const AdminSignIn = lazy(() => import("../src/community/pages/AdminSignIn"));

const App = () => {
  // const userData = useSelector((state) => state.auth?.userData);
  const userData = useSelector(selectors.selectUserInfo);

  // const [storedUserData, setStoredUserData] = useState();
  const dispatch = useDispatch();
  const navigateSignIn = useNavigate();

  const handleSignin = async () => {
    if (!userData) {
      const user = localStorage.getItem("user");
      // setStoredUserData(user);
      let test = JSON.parse(user);
      const formData = new FormData();
      formData.append("email", JSON.parse(user)?.email);
      formData.append("password", JSON.parse(user)?.password);
      await dispatch(await signInAction(formData, navigateSignIn));
    };
  };

  useEffect(() => {
    handleSignin();
  }, [userData]);


  const adminAccessToken = JSON.parse(
    localStorage.getItem("Admin")
  )?.accessToken;

  return (
    <Suspense fallback={<FallbackLoading />}>
      <Routes>
        <Route element={<PrivateRoute userData={userData} />}>
          {privateRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>

        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        <Route
          path="/com/signin"
          // element={userData ? <Navigate to="/" /> : <SignIn />}
          element={userData ? <Navigate to="/" /> : <Navigate to="/dang-nhap" />}
        />

        <Route
          path="/com/admin/signin"
          element={
            // adminAccessToken ? <Navigate to="/admin" /> : <AdminSignIn />
            adminAccessToken ? <Navigate to="/com/admin" /> : <Navigate to="/dang-nhap" />
          }
        />

        <Route
          path="/com/admin"
          element={
            // adminAccessToken ? <AdminPanel /> : <Navigate to="/admin/signin" />
            adminAccessToken ? <AdminPanel /> : <Navigate to="/dang-nhap" />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;