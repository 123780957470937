const titleMap = {
  "/com/": "News Feed",
  "/com": "News Feed",
  "/com/home": "News Feed",
  "/com/signin": "Sign In",
  "/com/signup": "Sign Up",
  "/com/profile": "User Profile",
  "/com/saved": "Saved Posts",
  "/com/edit-profile": "Edit Profile",
  "/com/communities": "Communities",
  "/com/my-communities": "My Communities",
  "/com/following": "Following",
  "/com/devices-locations": "Devices & Locations",
  "/com/verify-email": "Verify Email",
  "/com/email-verified": "Email Verified",
  "/com/block-device": "Block Device",
  "/com/login-verified": "Login Verified",
};
/**
 * Returns the title of a page based on its route path.
 * @param {string} path - The path of the page route.
 * @returns {string} The title of the page with the site name appended.
 */
export const getTitleFromRoute = (path) => {
  if (titleMap[path]) {
    return `${titleMap[path]} | LT Handmade`;
  }

  const userProfileRegex = /^\/user\/(\w+)$/;
  const postRegex = /^\/(my\/)?post\/(\w+)$/;
  const communityRegex =
    /^\/community\/(\w+)(\/report|\/reported-post|\/moderator)?$/;

  if (userProfileRegex.test(path)) {
    return "User Profile | LT Handmade";
  } else if (postRegex.test(path)) {
    return "Post | LT Handmade";
  } else if (communityRegex.test(path)) {
    return "Community | LT Handmade";
  }

  return "LT Handmade";
};
