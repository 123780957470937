import CartService from '../../../b2c/shared/services/cart.service';
import { hideLoading, showLoading } from '../actions/globalActions';
import * as types from '../constants/cartConstants'

export const setCartProducts = (products) => ({
  type: types.SET_CART_PRODUCTS,
  payload: products,
});

export const getCartByUserId = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const updatedCart = await CartService.getCartByUserId(userId);
      dispatch(setCartProducts(updatedCart.products));
      return true;
    } catch (error) {
      return Promise.reject(error?.response?.data?.message || error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const addProductToCart = (userId, products) => {
    return async (dispatch) => {
      try {
        dispatch(showLoading());
        const updatedCart = await CartService.update(userId, { products });
        dispatch(setCartProducts(updatedCart.products));
        return true;
      } catch (error) {
        return Promise.reject(error?.response?.data?.message || error.message);
      } finally {
        dispatch(hideLoading());
      }
    };
  };
