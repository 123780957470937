import {
  LineChartOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { Button, Form, message, Col, Row, Statistic, Card, Flex } from "antd";
import { Drawer } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React, { useState, useEffect } from "react";
import {
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
// import faker from 'faker';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import LtFormDatePicker from "../../../core/components/lt-form-date-picker";
import LtFormDropdown from "../../../core/components/lt-form-dropdown";
import { OrderStatus } from "../../../shared/enums/order-status.enum";
import { StatisticService } from "../../../shared/services/statistic.service";
import { actions } from "../../../stores";
import Dashboard from "./Dashboard";
import ProductLine from "./ProductLine";
import RateGuestNMember from "./RateGuestNMembers";
import { set } from "lodash";
const tabList = [
  {
    key: "tab1",
    tab: "Giá trị đơn hàng",
  },
  {
    key: "tab2",
    tab: "Doanh thu các loại sản phẩm",
  }
];
const labels = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];
const Overview = () => {
  const currentMonth = new Date().getMonth() + 1;
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [ativeUsers, setActiveUsers] = useState(0);
  const [totalBillOrdersThisYear, setTotalBillOrdersThisYear] = useState(0);
  const [revenueGuest, setRevenueGuest] = useState(13);
  const [revenueMember, setRevenueMember] = useState(-12);
  const [likes, setLikes] = useState(0);
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [open, setOpen] = useState(false);

  const [revenueProductType, setRevenueProductType] = useState();
  
  async function getRevenueProductEachMonth() {
    
    const {revenueProductEachMonth} = await StatisticService.revenueProductEachMonth();
    const colors = revenueProductEachMonth.map(() => {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return {
        borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
      };
    });
    const datasets = revenueProductEachMonth.map((item, index) => ({
      label: item.name,
      data: item.monthlyTotals,
      borderColor: colors[index].borderColor,
      backgroundColor: colors[index].backgroundColor,
    }));
    setRevenueProductType({ labels, datasets });
  }
  const [
    totalBillOrdersWithUserThisMonth,
    setTotalBillOrdersWithUserThisMonth,
  ] = useState(0);
  const [
    totalBillOrdersWithoutUserThisMonth,
    setTotalBillOrdersWithoutUserThisMonth,
  ] = useState(0);

  
  const onTab1Change = (key) => {
    
    setActiveTabKey1(key);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const init = async () => {
    try {
      
      const {
        totalLikes,
        totalUsers,
        totalBillOrdersThisYear,
        totalBillOrdersWithoutUserThisMonth,
        totalBillOrdersWithoutUserLastMonth,
        totalBillOrdersWithUserThisMonth,
        totalBillOrdersWithUserLastMonth,
      } = await StatisticService.intialOverview();
      const revenueGrowthGuest =
        ((totalBillOrdersWithoutUserThisMonth -
          totalBillOrdersWithoutUserLastMonth) /
          (totalBillOrdersWithoutUserLastMonth === 0
            ? 1
            : totalBillOrdersWithoutUserLastMonth)) *
        100;
      const revenueGrowthMember =
        ((totalBillOrdersWithUserThisMonth - totalBillOrdersWithUserLastMonth) /
          (totalBillOrdersWithUserLastMonth === 0
            ? 1
            : totalBillOrdersWithUserLastMonth)) *
        100;
      
      setTotalBillOrdersWithUserThisMonth(totalBillOrdersWithUserThisMonth);
      setTotalBillOrdersWithoutUserThisMonth(
        totalBillOrdersWithoutUserThisMonth
      );
      setRevenueGuest(revenueGrowthGuest.toFixed(3));
      setRevenueMember(revenueGrowthMember.toFixed(3));

      setLikes(totalLikes);
      setActiveUsers(totalUsers);
      setTotalBillOrdersThisYear(totalBillOrdersThisYear);
    } catch (error) {
      messageApi.error(error.message);
    }
  };
  useEffect(() => {
    init();
    getRevenueProductEachMonth();
    // window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {contextHolder}
      <Drawer
        title={`Tỉ lệ doanh thu Thành Viên/Khách tháng ${currentMonth}`}
        placement="right"
        closable={false}
        size = "large"
        onClose={onClose}
        open={open}
        getContainer={false}
      >
        <RateGuestNMember
          totalBillOrdersWithUserThisMonth={totalBillOrdersWithUserThisMonth}
          totalBillOrdersWithoutUserThisMonth={
            totalBillOrdersWithoutUserThisMonth
          }
        />
      </Drawer>
      <Flex gap="middle" horizontal>
        <Col span={4}>
          <Card bordered={true} flex={2}>
            <Statistic
              title="Lượt thích"
              value={likes}
              prefix={<LikeOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={true}>
            <Statistic title="Số lượng tài khoản" value={ativeUsers} />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={true} hoverable onClick={showDrawer}>
            <Statistic
              title={`Doanh thu tháng ${currentMonth} (Thành Viên)`}
              value={revenueGuest}
              valueStyle={
                revenueGuest > 0 ? { color: "#3f8600" } : { color: "#cf1322" }
              }
              prefix={
                revenueGuest > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />
              }
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={true} hoverable onClick={showDrawer}>
            <Statistic
              title={`Doanh thu tháng ${currentMonth} (Khách)`}
              value={revenueMember}
              valueStyle={
                revenueMember > 0 ? { color: "#3f8600" } : { color: "#cf1322" }
              }
              prefix={
                revenueMember > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />
              }
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={7}>
          <Card bordered={true}>
            <Statistic
              title="Tổng doanh thu năm (VND)"
              value={totalBillOrdersThisYear}
              precision={2}
            />
          </Card>
        </Col>
      </Flex>
      <Card
        className="mt-4"
        style={{ width: "100%" }}
        title={<span style={{ fontSize: "24px" }}>Bảng thống kê</span>}
        tabList={tabList}
        onTabChange={onTab1Change}
      >
        {activeTabKey1 === "tab1" && <Dashboard />}
        {activeTabKey1 === "tab2" && <ProductLine revenueProductType = {revenueProductType} />}
      
      </Card>
    </>
  );
};

export default Overview;
