import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

// Global
import * as globalActions from './global/global.actions';
import globalReducer from './global/global.reducer';
import * as globalSelectors from './global/global.selectors';
import { configureStore } from "@reduxjs/toolkit";

// Cart
import * as cartActions from './cart/cart.actions';
import cartReducer from './cart/cart.reducer';
import * as cartSelectors from './cart/cart.selectors';

//comunity
import postsReducer from "../../community/redux/reducers/posts";
import authReducer from "../../community/redux/reducers/auth";
import communityReducer from "../../community/redux/reducers/community";
import moderationReducer from "../../community/redux/reducers/moderation";
import userReducer from "../../community/redux/reducers/user";
import adminReducer from "../../community/redux/reducers/admin";


const rootReducer = combineReducers({
  posts: postsReducer,
  auth: authReducer,
  community: communityReducer,
  moderation: moderationReducer,
  user: userReducer,
  admin: adminReducer,
  global: globalReducer,
  cart: cartReducer,
});
const middlewares = [thunk];
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  // rootReducer,
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
export const actions = {
  ...globalActions,
  ...cartActions,
};
export const selectors = {
  ...globalSelectors,
  ...cartSelectors,
};
