import { Button, Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import LtFormTextArea from '../../../core/components/lt-form-textarea';
import { useTranslation } from "react-i18next";

const AddComment = ({ onAddComment }) => {
  const [t] = useTranslation("global");

  const { reset, watch, control, handleSubmit } = useForm({ defaultValues: { content: '' } });

  const handleAddComment = (formValue) => {
    reset();
    onAddComment(formValue);
  };

  return (
    <>
      <Form layout='vertical' onFinish={handleSubmit(handleAddComment)}>
        <LtFormTextArea
          name='content'
          control={control}
          placeholder={t("Comment.placeholder")}
          label={t("Comment.placeholder")}
        />
        <Button type='primary' size='large' disabled={!watch('content').trim()} htmlType='submit'>
        {t("Comment.button")}
        </Button>
      </Form>
    </>
  );
};

export default AddComment;
