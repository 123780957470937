import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Image, Space, Tooltip, message } from 'antd';
import moment from 'moment/moment';
// import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
// import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import LtDynamicTable from '../../../core/components/lt-dynamic-table/LtDynamicTable';
import LtFormModal from '../../../core/components/lt-form-modal';
import { actions } from '../../../stores';
import AddCommunity from './AddCommunity';
import { NavLink } from 'react-router-dom';
//=======================================================================================================
import { useSelector, useDispatch } from "react-redux";
import {
  getCommunitiesAction,
  getModeratorsAction,
  addModeratorAction,
  removeModeratorAction,
  getCommunityAction,
} from "../../../../community/redux/actions/adminActions";
import { createCommunityAction } from "../../../../community/redux/actions/communityActions";
import React, { useEffect, useMemo, useState } from 'react';

//=======================================================================================================
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Communities = () => {
  //=======================================================================================================
  const dispatch = useDispatch();

  const communities = useSelector((state) => state.admin?.communities);
  const supervisor = useSelector((state) => state.admin?.supervisor);
  const community = useSelector((state) => state.admin?.community);

  useEffect(() => {
    dispatch(getCommunitiesAction());
    dispatch(getModeratorsAction());
  }, [dispatch]);

  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedCommunityData, setSelectedCommunityData] = useState(null);
  const [selectedModerator, setSelectedModerator] = useState(null);
  const [newModerator, setNewModerator] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChangingCommunity, setIsChangingCommunity] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState();

  const [messageApi, contextHolder] = message.useMessage();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      supervisor: [],
      description: '',
    },
  });

  const createCommunity = async (formValue) => {
  
    if (!fileList.length) {
      return messageApi.error('Vui lòng cung cấp hình ảnh cộng đồng');
    }

    const formData = new FormData();
    for (const field in formValue) {
      formData.append(field, formValue[field]);
    }
    if (fileList && fileList.length) {
      fileList.forEach((file) => {
        formData.append('banner', file.originFileObj);
      });
    }

    try {
      dispatch(actions.showLoading());
      await dispatch(createCommunityAction(formData));
      messageApi.success('Thêm cộng đồng thành công');
      reset();
      setFileList([]);
      dispatch(getCommunitiesAction());
      setIsOpen(false);
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const handleCommunitySelect = async (community) => {
    setSelectedCommunity(community);
    setIsChangingCommunity(true);
    await dispatch(getCommunityAction(community?._id));
    setIsChangingCommunity(false);
  };

  useEffect(() => {
    setSelectedCommunityData(community);
  }, [community]);

  const handleModeratorSelect = (moderator) => {
    setSelectedModerator(moderator);
  };

  const handleRemoveModerator = async (moderator) => {
    setIsUpdating(true);
    await dispatch(
      removeModeratorAction(selectedCommunityData?._id, moderator?._id)
    );
    await dispatch(getCommunityAction(selectedCommunityData?._id));
    await dispatch(addModeratorAction(selectedCommunityData?._id, newModerator));
    await dispatch(getModeratorsAction());
    setIsUpdating(false);
  };
  const handleAddModerator = async () => {
    setIsUpdating(true);
    await dispatch(addModeratorAction(selectedCommunityData?._id, newModerator));
    await dispatch(getCommunityAction(selectedCommunityData?._id));
    await dispatch(getModeratorsAction());
    setNewModerator("");
    setIsUpdating(false);
  };

 

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file?.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleSubmitCommunityForm = (formValue) => {
    if (isUpdating) {
      updateCommunity(formValue);
    } else {
      createCommunity(formValue);
    }
  };

  const updateCommunity = async (formValue) => {
    try {
      dispatch(actions.showLoading());
      const formData = new FormData();
      // formData.append('name', formValue?.name);
      // if (imageFile) {
      //   formData.append('image', imageFile);
      //   formData.append('imageUrl', imageUrl);
      // }
      // await ProductTypesService.update(productTypeId, formData);
      // handleCloseModal();
      // getProductTypes();
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const openUpdateCommunityDialog = async (community) => {
  
    reset({
      name: community?.name,
      banner: community?.banner,
    });
    // setImageUrl(productType.imageUrl);
    // setProductTypeId(productType?._id);
    setIsUpdating(true);
    setIsOpen(true);
  }

  const tableColumns = useMemo(() => {
    return [
      {
        title: 'ID',
        key: '_id',
        dataIndex: '_id',
        render: (value) => value.toString().slice(-7),
      },
      {
        title: 'Tên Cộng Đồng',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Hinh ảnh',
        dataIndex: 'banner',
        key: 'banner',
        render: (value) => (
          <Image src={value} style={{ width: 120, height: 120, objectFit: 'contain' }} />
        ),
        align: 'center',
      },
      {
        title: 'Số Lượng Thành Viên',
        dataIndex: 'amountMember',
        key: 'amountMember',
        align: 'center',
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator=',' />,
      },{
        key: 'action',
        title: null,
        dataIndex: null,
        render: (_, community) => (
          <Space>
            <Tooltip title='Cập nhật'>
                  <NavLink to={`/admin/cap-nhat-cong-dong/${community?._id}`}>
                    <Button size='large' className='button-color-theme' type='primary' shape='circle' icon={<EditOutlined />} />
                  </NavLink>
                </Tooltip>
            {/* <Tooltip title='Xoá'>
              <Button
                danger
                size='large'
                type='primary'
                shape='circle'
                icon={<DeleteOutlined />}
                // onClick={() => handleDeleteProductType(productType?._id)}
              />
            </Tooltip> */}
          </Space>
        ),
        align: 'center',
      },
    ];
  }, []);

  return (
    <>
      {contextHolder}
      <div className='d-flex justify-content-start align-items-center'>
        <Button
          type='primary'
          size='large'
          className='button-color-theme'
          icon={<PlusOutlined />}
          onClick={() => {
            setIsOpen(true);
            reset();
            setFileList([]);
          }}>
          Thêm cộng đồng
        </Button>
      </div>
      <div className='pt-3'>
        <LtDynamicTable
          cols={tableColumns}
          dataSrc={communities}
          hasFilters
          searchByFields={['name', 'id']}
          pageSize={6}
        />
      </div>
      <LtFormModal
        width={'50vw'}
        isOpen={isOpen}
        title={isUpdating ? 'CẬP NHẬT CỘNG ĐỒNG' : 'THÊM CỘNG ĐỒNG'}
        okBtnText='Tạo'
        cancelBtnText='Huỷ'
        onCancel={() => setIsOpen(false)}
        onSubmit={handleSubmit(handleSubmitCommunityForm)}>
        <AddCommunity
          control={control}
          errors={errors}
          handleCancel={handleCancel}
          previewTitle={previewTitle}
          previewImage={previewImage}
          previewOpen={previewOpen}
          fileList={fileList}
          handleChange={handleChange}
          handlePreview={handlePreview}
          supervisor={supervisor}
        // productTypes={productTypes.map((type) => ({ label: type?.name, value: type?._id }))}
        />
      </LtFormModal>
    </>
  );
};

export default Communities;
