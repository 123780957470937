import {
  ArrowLeftOutlined,
  CarOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  FormOutlined,
  GiftOutlined,
} from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Form, Image, Radio, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import LtDynamicTable from '../../core/components/lt-dynamic-table';
import LtFormInput from '../../core/components/lt-form-input';
import { DeliveryStatus } from '../../shared/enums/delivery-status.enum';
import { OrderStatus } from '../../shared/enums/order-status.enum';
import { PaymentMethods } from '../../shared/enums/payment-methods.enum';
import { PaymentStatus } from '../../shared/enums/payment-status.enum';
import { OrdersService } from '../../shared/services/orders.service';
import { actions, selectors } from '../../stores';
import Payments from './payments/Payments';
import './styles.scss';
import { useTranslation } from "react-i18next";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const paymentOptions = [
  {
    label: PaymentMethods.CashOnDelivery,
    value: PaymentMethods.CashOnDelivery,
    icon: <DollarOutlined style={{ fontSize: 24 }} />,
  },
  {
    label: PaymentMethods.Credit,
    value: PaymentMethods.Credit,
    icon: <CreditCardOutlined style={{ fontSize: 36 }} />,
  },
];

const OrderPayment = () => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();
  const [paymentIntents, setPaymentIntents] = useState(null);
  const [options, setOptions] = useState({
    appearance: {
      theme: 'stripe',
    },
    clientSecret: null,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();
  const products = useSelector(selectors.selectProducts);
  const userData = useSelector(selectors.selectUserInfo);
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notes: '',
      phone: '',
      fullName: '',
      deliveryAddress: '',
      paymentMethod: PaymentMethods.CashOnDelivery,
    },
  });

  const handleCheckout = async (formValues) => {
    try {
      
      dispatch(actions.showLoading());
      const payload = {
        userId: userData?.id,
        paymentMethod: formValues.paymentMethod,
        paymentStatus:
          formValues.paymentMethod === PaymentMethods.Credit
            ? PaymentStatus.PAID
            : PaymentStatus.NOT_YET_PAY,
        deliveryStatus: DeliveryStatus.PENDING,
        orderStatus: OrderStatus.PENDING,
        totalBill,
        notes: formValues.notes,
        fullName: formValues.fullName.trim() || userData?.name.trim(),
        phone: formValues.phone || userData.phone,
        deliveryAddress: formValues.deliveryAddress.trim() || userData?.address.trim(),
        products: products.map((product) => {
          const { _id, ...rest } = product;
          return rest;
        }),
      };
      await OrdersService.createOrder(payload);
      messageApi.success(t("OrderPayment.noti1"));
      dispatch(actions.setCartProducts([]));
      if(userData){
        setTimeout(() => {
          navigate('/don-hang-cua-toi');
        }, 500);
      }else{
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
      
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const tableColumns = useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'productId',
        key: '_id',
        render: (value) => value.slice(-8, -1),
        align: 'center',
      },
      {
        title: t("OrderPayment.title1"),
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: t("OrderPayment.title2"),
        dataIndex: 'image',
        key: 'image',
        render: (value) => (
          <Image src={value} style={{ width: 120, height: 120, objectFit: 'contain' }} />
        ),
        align: 'center',
      },
      {
        title: t("OrderPayment.title3"),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
      },
      {
        title: t("OrderPayment.title4"),
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator=',' />,
      },
      {
        title: t("OrderPayment.title5"),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        align: 'center',
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator=',' />,
      },
    ];
  }, [t, i18n.language]);

  const totalBill = useMemo(() => {
    return products.reduce((total, product) => total + product.totalPrice, 0);
  }, [products]);

  useEffect(() => {
    const initCheckout = async () => {
      try {
        dispatch(actions.showLoading());
        dispatch(actions.getCartByUserId(userData.id));
        if (totalBill >= 20000) {
          const paymentIntents = await OrdersService.createPaymentIntents(totalBill);
          setPaymentIntents(paymentIntents);
          setOptions({ ...options, clientSecret: paymentIntents.client_secret });
        }
        reset({
          fullName: userData?.name || '',
          phone: userData?.phone || null,
          deliveryAddress: userData?.address || null,
        });
      } catch (error) {
        messageApi.error(error?.response?.data?.message || error.message);
      } finally {
        dispatch(actions.hideLoading());
      }
    };
    const initCheckoutGuest = async () => {
      try{
        dispatch(actions.showLoading());
        if (totalBill >= 20000) {
          const paymentIntents = await OrdersService.createPaymentIntents(totalBill);
          setPaymentIntents(paymentIntents);
          setOptions({ ...options, clientSecret: paymentIntents.client_secret });
        }
      } catch (error) {
        messageApi.error(error?.response?.data?.message || error.message);
      } finally{
        dispatch(actions.hideLoading());

      }
    }
    
    if(userData){
      initCheckout();
    }else{
      initCheckoutGuest();
    }
  }, []);

  return (
    <>
      {contextHolder}
      <div className='container-fluid p-4'>
        <h2 className='text-center'>{t("OrderPayment.h1")}</h2>
        <div className='flex ai-center jc-center py-2'>
          <img src='/images/divider.png' alt='Divider' />
        </div>
        <div className='container py-3'>
          <Form layout='vertical' onFinish={handleSubmit(handleCheckout)}>
            <h4 className='my-2 text-info'>
              <span className='mr-2'>
                <CarOutlined />
              </span>
              {t("OrderPayment.h2")}
            </h4>
            <LtFormInput
              label={t("OrderPayment.label1")}
              control={control}
              error={errors.fullName}
              name='fullName'
              placeholder={t("OrderPayment.placeholder1")}
              rules={{
                required: t("OrderPayment.warning1"),
              }}
            />
            <LtFormInput
              label={t("OrderPayment.label2")}
              control={control}
              error={errors.phone}
              name='phone'
              placeholder={t("OrderPayment.placeholder2")}
              rules={{
                required: t("OrderPayment.warning2"),
                pattern: {
 
                  value: /^(\+84|0)(3|5|7|8|9)(\d{8})$/,
                  message: t("OrderPayment.warning3"),
                },
              }}
            />
            <LtFormInput
              label={t("OrderPayment.label3")}
              control={control}
              error={errors.deliveryAddress}
              name='deliveryAddress'
              placeholder={t("OrderPayment.placeholder3")}
              rules={{
                required: t("OrderPayment.warning4"),
              }}
            />
            <hr />
            <h4 className='my-2 text-info'>
              <span className='mr-2'>
                <DollarOutlined />
              </span>
              {t("OrderPayment.h3")}
            </h4>
            <Form.Item>
              <Controller
                name='paymentMethod'
                control={control}
                rules={{
                  required: t("OrderPayment.warning5"),
                }}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    {paymentOptions.map((option) => (
                      <div key={option.value} className='p-3'>
                        <Radio value={option.value} className='checkout-option'>
                          <span className='mx-2'>{option.icon}</span>
                          <span className='text-capitalize'>{option.label}</span>
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                )}
              />
            </Form.Item>
            {watch('paymentMethod') === PaymentMethods.Credit ? (
              options && options.clientSecret ? (
                <Elements stripe={stripePromise} options={options}>
                  <Payments />
                </Elements>
              ) : (
                <Alert
                  message={t("OrderPayment.warning6")}
                  type='error'
                />
              )
            ) : null}
            <hr />
            <h4 className='my-2 text-info'>
              <span className='mr-2'>
                <GiftOutlined />
              </span>
              {t("OrderPayment.h4")}
            </h4>
            <hr />
            <h4 className='my-3 text-info'>
              <span className='mr-2'>
                <FormOutlined />
              </span>
              {t("OrderPayment.h5")}
            </h4>
            <Form.Item>
              <Controller
                name='notes'
                control={control}
                render={({ field }) => (
                  <textarea
                    className='form-control'
                    rows={3}
                    placeholder={t("OrderPayment.placeholder4")}
                    {...field}></textarea>
                )}
              />
            </Form.Item>
            <hr />
            <div className='d-flex align-items-center justify-content-end'>
              <div className='w-50 text-right'>
                <div className='checkout-detail'>
                  <h4>{t("OrderPayment.h6")}</h4>
                  <h4>
                    <NumericFormat value={totalBill} displayType='text' thousandSeparator=',' />
                  </h4>
                </div>
                <div className='checkout-detail'>
                  <span>{t("OrderPayment.label4")}</span>
                  <NumericFormat value={totalBill} displayType='text' thousandSeparator=',' />
                </div>
                <div className='checkout-detail'>
                  <span>{t("OrderPayment.label5")}</span>
                  <NumericFormat value={0} displayType='text' thousandSeparator=',' />
                </div>
              </div>
            </div>
            <hr />
            <div className='checkout-detail'>
              <NavLink to='/gio-hang-cua-toi'>
                <Button size='large' className='ant-btn-default' type='dashed' icon={<ArrowLeftOutlined />}>
                {t("OrderPayment.button1")}
                </Button>
              </NavLink>
              <Button
                size='large'
                type='primary'
                className='ml-4'
                htmlType='submit'
                disabled={!totalBill}
                icon={<DollarCircleOutlined />}>
                {t("OrderPayment.button2")}
              </Button>
            </div>
          </Form>
        </div>
        <hr />
        <h3 className='text-center'>{t("OrderPayment.h7")}</h3>
        <div className='container'>
          <LtDynamicTable cols={tableColumns} dataSrc={products} rowKey='_id' />
        </div>
      </div>
    </>
  );
};

export default OrderPayment;
