import { DeleteOutlined } from '@ant-design/icons';
import { Button, Descriptions, Image, Table, Tag, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import LtDynamicTable from '../../core/components/lt-dynamic-table';
import { DeliveryStatus } from '../../shared/enums/delivery-status.enum';
import { OrderStatus } from '../../shared/enums/order-status.enum';
import { PaymentStatus } from '../../shared/enums/payment-status.enum';
import { OrdersService } from '../../shared/services/orders.service';
import { actions, selectors } from '../../stores';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";


const colorMap = {
  [PaymentStatus.NOT_YET_PAY]: "orange",
  [PaymentStatus.PAID]: "green",
  [DeliveryStatus.IN_PROGRESS]: "blue",
  [DeliveryStatus.DELIVERED_SUCCESS]: "green",
  [DeliveryStatus.DELIVERED_FAILED]: "red",
  [OrderStatus.IN_PROGRESS]: "blue",
  [OrderStatus.SUCCESS]: "green",
  [OrderStatus.FAILED]: "red",
};

const Order = () => {
  const [t, i18n] = useTranslation("global");

  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectors.selectUserInfo);
  const [messageApi, contextHolder] = message.useMessage();

  const handleRating = async (product) => {
    try {
      var rating;
      var comment;
      
      Swal.fire({
        title: "Đánh giá sản phẩm",
        html: `
        <div class="swal2-radio" style="display: flex; flex-direction: column; align-items: flex-start;">
        <label><input type="radio" name="swal2-radio" value="1"><span class="swal2-label">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
        </span></label>
        <label><input type="radio" name="swal2-radio" value="2"><span class="swal2-label">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
        </span></label>
        <label><input type="radio" name="swal2-radio" value="3"><span class="swal2-label">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
        </span></label>
        <label><input type="radio" name="swal2-radio" value="4"><span class="swal2-label">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
        </span></label>
        <label><input type="radio" name="swal2-radio" value="5" checked><span class="swal2-label">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
          <img src="/static/media/star-fill.9f18b82d2b1bc1b80b89c3e3e436dd3f.svg" alt="star" style="width: 20px; height: 20px; float: right;">
        </span></label></div>
        <textarea id="swal2-textarea" aria-label="Type your message here" class="swal2-textarea" placeholder="Đánh giá của bạn là gì?" style="display: flex; width: 80%"></textarea>
        `,
        focusConfirm: false,
        preConfirm: () => {
          rating = document.querySelector(
            'input[name="swal2-radio"]:checked'
          )?.value;
          comment = document.getElementById("swal2-textarea")?.value;
          return {
            comment: comment,
            rating: rating,
          };
        },
        showCancelButton: true,
        cancelButtonText: "Đóng",
        confirmButtonText: "Đánh giá",
      }).then(async (result) => {
        if (result.isConfirmed) {
          
          dispatch(actions.showLoading());
          let userId = currentUser.id;
          const updatedOrder = await OrdersService.rateProduct(
            product.orderId,
            product._id,
            product.productName,
            rating,
            comment,
            userId,
          );
          // Find the index of the updated order
          const orderIdx = orders.findIndex((o) => o._id === updatedOrder._id);
          if (orderIdx !== -1) {
            // Update the orders array with the new order data
            orders[orderIdx] = JSON.parse(JSON.stringify(updatedOrder));
            // Update the state to trigger a re-render
            orders.forEach((order) => {
              order.products.forEach((product) => {
                product.orderStatus = order.orderStatus;
                product.orderId = order._id;
              });
            });
            setOrders([...orders]);
            messageApi.success(`Đã đánh giá sản phẩm ${product.productName}`);
          }
          dispatch(actions.hideLoading());
        } else if (result.isDenied) {
          // Swal.fire("Changes are not saved", "", "info");
        }
      });
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };
  const handleCancelOrder = async (order) => {
    try {
      const { value: notes, isConfirmed } = await Swal.fire({
        title: t("Order.title1"),
        input: 'textarea',
        inputLabel: t("Order.input1"),
        inputPlaceholder: t("Order.placeholder1"),
        inputValidator: (value) => {
          if (!value.trim()) {
            return t("Order.warning1");
          }
        },
        showCancelButton: true,
        cancelButtonText: t("Order.button1"),
        confirmButtonText: t("Order.button2"),
        confirmButtonColor: 'red',
      });
      const changes = {
        notes,
        orderStatus: OrderStatus.FAILED,
        deliveryStatus: DeliveryStatus.DELIVERED_FAILED,
      };
      
      if (!isConfirmed) {
        return;
      }
      dispatch(actions.showLoading());
      const updatedOrders = await OrdersService.updateOrder(order?._id, changes);
      const orderIdx = orders.findIndex((o) => o?._id === updatedOrders?._id);
      if (orderIdx !== -1) {
        orders[orderIdx] = JSON.parse(JSON.stringify(updatedOrders));
        setOrders([...orders]);
      }
      messageApi.success(t("Order.noti1"));
    } catch (error) {
      messageApi.error(error?.response?.data?.message || error.message);
    } finally {
      dispatch(actions.hideLoading());
    }
  };

  const mainTableColumns = useMemo(() => {
    return [
      {
        title: "ID",
        key: "_id",
        dataIndex: "_id",
        render: (value) => value.slice(0, 8),
        align: "center",
      },
      {
        title: t("Order.title2"),
        key: 'orderStatus',
        dataIndex: 'orderStatus',
        render: (value) => (
          <Tag color={colorMap[value]}>
            <span className="text-capitalize">{value}</span>
          </Tag>
        ),
        align: "center",
      },
      {
        title: t("Order.title3"),
        key: 'totalBill',
        dataIndex: 'totalBill',
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator=',' />,
        align: 'center',
      },
      {
        title: t("Order.title4"),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('HH:mm - DD/MM/YYYY'),
        align: 'center',
      },
      {
        title: t("Order.title5"),
        key: 'notes',
        dataIndex: 'notes',
        render: (value) => (
          <div
            style={{
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {value}
          </div>
        ),
      },
      {
        title: "",
        key: "actions",
        dataIndex: null,
        render: (_, order) => (
          <>
            {order.orderStatus === OrderStatus.PENDING && (
              <Button
                size="large"
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleCancelOrder(order)}>
                {t("Order.button2")}
              </Button>
            )}
          </>
        ),
        align: "center",
      },
    ];
  }, [orders.length, t, i18n.language]); // Thêm i18n.language vào dependency
  

  const subTableColumns = useMemo(() => {
    return [
      {
        title: t("Order.title6"),
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: t("Order.title7"),
        dataIndex: 'image',
        key: 'image',
        render: (value) => (
          <Image
            src={value}
            style={{ width: 120, height: 120, objectFit: "contain" }}
          />
        ),
        align: "center",
      },
      {
        title: t("Order.title8"),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
      },
      {
        title: t("Order.title9"),
        dataIndex: 'price',
        key: 'price',
        align: 'center',
      },
      {
        title: t("Order.title10"),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        align: 'center',
      },
    ];
  }, [t, i18n.language]); // Thêm i18n.language vào dependency
  

  useEffect(() => {
    const getOrders = async () => {
      try {
        dispatch(actions.showLoading());
        const orders = await OrdersService.getOrdersByUserId(currentUser.id);
        orders.forEach((order) => {
          order.products.forEach((product) => {
            product.orderStatus = order.orderStatus;
            product.orderId = order._id;
          });
        });

        setOrders(orders);
      } catch (error) {
        messageApi.error(error?.response?.data?.message || error.message);
      } finally {
        dispatch(actions.hideLoading());
      }
    };
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    getOrders();
  }, []);

  return (
    <>
      <div className="container pb-5">
        {contextHolder}
        <div className='pt-5'>
          <h1 className='text-center'>{t("Order.h1")}</h1>
        </div>
        <div className="ai-center jc-center flex pb-4 pt-2">
          <img src="/images/divider.png" alt="Divider" />
        </div>
        <Table
          rowKey="_id"
          columns={mainTableColumns}
          dataSource={orders}
          pagination={{ pageSize: 12, hideOnSinglePage: true }}
          expandable={{
            expandedRowRender: (order) => (
              <>
                {order.orderStatus === OrderStatus.FAILED && (
                  <Descriptions title={t("Order.Descriptions1")} column={1}>
                    <Descriptions.Item label={t("Order.Descriptions1")}>
                      <em>{order.notes}</em>
                    </Descriptions.Item>
                  </Descriptions>
                )}
                <Descriptions title={t("Order.Descriptions2")} column={1}>
                  <Descriptions.Item label={t("Order.Descriptions2")}>
                    <span className='text-capitalize'>{order.paymentMethod}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Order.Descriptions3")}>
                    <Tag color={colorMap[order.paymentStatus]}>
                      <span className="text-capitalize">
                        {order.paymentStatus}
                      </span>
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title={t("Order.Descriptions4")} column={1}>
                  <Descriptions.Item label={t("Order.Descriptions4")}>
                    <span className='text-capitalize'>{order.deliveryType}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Order.Descriptions5")}>
                    <Tag color={colorMap[order.deliveryStatus]}>
                      <span className="text-capitalize">
                        {order.deliveryStatus}
                      </span>
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title={t("Order.Descriptions6")} column={1}>
                  <Descriptions.Item label={t("Order.input2")}>{order.fullName}</Descriptions.Item>
                  <Descriptions.Item label={t("Order.input3")}>{order.phone}</Descriptions.Item>
                  <Descriptions.Item label={t("Order.input4")}>
                    {order.deliveryAddress}
                  </Descriptions.Item>
                </Descriptions>
                <hr />
                <h5 className='text-center page-title'>{t("Order.Descriptions7")}</h5>
                <LtDynamicTable cols={subTableColumns} dataSrc={order.products} rowKey='_id' />
              </>
            ),
          }}
        />
      </div>
    </>
  );
};

export default Order;
