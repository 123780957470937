import axiosClient from '../interceptors';

const ProductAttributeService = {
  getAll: async () => {
    const { data: responseResults } = await axiosClient.get('/api/product-attribute/get-all');
    
    return responseResults.data;
  },
  getAllDeleted: async () => {
    
    const { data: responseResults } = await axiosClient.get('/api/product-attribute/get-all-deleted');
    return responseResults.data;
  }
  ,

  create: async (formData) => {
    
    const path = '/api/product-attribute/create';
    const { data: responseResults } = await axiosClient.post(path, formData
        , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
);
    return responseResults.data;
  },

  update: async (id, formData) => {
    const path = `/api/product-attribute/${id}`;
    const { data: responseResults } = await axiosClient.put(path, formData
        , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
);
    return responseResults.data;
  },

  delete: async (id) => {
    await axiosClient.delete(`/api/product-attribute/${id}`);
    return null;
  },
};

export default ProductAttributeService;
