import React ,{useEffect, useState}from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



function RateGuestNMembers({ totalBillOrdersWithUserThisMonth, totalBillOrdersWithoutUserThisMonth }) {
  const [data, setData] = useState( {
    labels: ['Thành Viên', 'Khách'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalBillOrdersWithUserThisMonth, totalBillOrdersWithoutUserThisMonth],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {

  },[]);

return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <Doughnut data={data} />
    </div>
)
}
export default RateGuestNMembers;
